import { EditorReadyFn, OnEventFn } from '@wix/yoshi-flow-editor';
import { maybeInstallMembersArea } from '@wix/members-area-integration-kit';

import { InstallationOriginType } from '@wix/editor-platform-sdk-types';

import GroupPlatform from './GroupPlatform';
import { AppActionEvent, PageActionsEvent } from './types/manifest';
import { HandleAction } from './types/editor-app';

const DSN =
  'https://f36cc48fb72b4d298c835f2793cf3b84@sentry-next.wixpress.com/1058';
const sentryConfiguration = { dataCallback: (data: any) => data };

let platform: GroupPlatform;
let sentryMonitor: any;

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefinitionId,
  options,
  flowAPI,
) => {
  const { firstInstall, initialAppData, origin, monitoring } = options;

  sentryMonitor = monitoring.createSentryMonitorForApp(
    DSN,
    sentryConfiguration,
  );
  editorSDK.info
    .getMetaSiteId('')
    .then((msid) => sentryMonitor.setTagsContext({ msid }));

  const isSilentInstallation =
    origin.info?.type === InstallationOriginType.SILENT_INSTALL_SITE_CREATION ||
    origin.info?.type === InstallationOriginType.SILENT_INSTALL;

  platform = new GroupPlatform(
    editorSDK,
    appDefinitionId,
    flowAPI,
    initialAppData,
  );

  try {
    if (firstInstall) {
      await platform.install();
      if (!flowAPI.experiments.enabled('specs.groups.StayOnGroupsPage')) {
        await platform.changeLandingPage();
      }

      if (!isSilentInstallation) {
        await maybeInstallMembersArea({ biData: options.biData });
      }
    } else {
      await platform.setGroupPageState(appDefinitionId);
      // For sites where groups members area was installed
      // TODO: 🚨 remove later see https://jira.wixpress.com/browse/GROUP-673
      await platform.deleteGroupsMemberArea();
    }
  } catch (e) {
    sentryMonitor.captureException(e);
    await platform.deleteApp();
    throw e;
  }
};
export const onEvent: OnEventFn = (event) => {
  try {
    switch (event.eventType as any) {
      case AppActionEvent.ADD_PAGE:
        return platform.addPage();
      case PageActionsEvent.REMOVE:
        return platform.deleteApp();
      case AppActionEvent.APP_ACTION_CLICK:
        return platform.performAction(event.eventPayload.actionId);
      default:
        return;
    }
  } catch (e) {
    sentryMonitor?.captureException(e);
  }
};

export const getAppManifest = async () => {
  return platform.getManifest();
};

export const handleAction: HandleAction = async ({ type, payload }) => {
  if (!platform) {
    // very strange case when editor ready is called after `handleAction`
    return;
  }

  sentryMonitor?.captureBreadcrumb({
    category: 'handleAction',
    message: JSON.stringify({ type, payload }),
    level: 'info',
  });
  sentryMonitor?.captureMessage('[Groups.platform]: handleAction', {
    level: 'debug',
  });

  switch (type) {
    case 'appInstalled':
      try {
        switch (payload.appDefinitionId) {
          case platform.appDefId:
            await platform.setGroupPageState(platform.appDefId);
            sentryMonitor?.captureMessage('[Groups.platform]: installed', {
              level: 'debug',
            });
            break;
          default:
        }
      } catch (error) {
        sentryMonitor?.captureException(error);
        await platform.deleteApp();
        throw error;
      }
      break;
    case 'migrate':
      try {
        const response = await platform.handleMigration(payload);
        sentryMonitor.captureMessage(
          '[Groups.platform]: end migration successfully',
          {
            level: 'debug',
            extra: {
              response,
            },
          },
        );
      } catch (error) {
        sentryMonitor.captureException(error);
        throw error;
      }
      break;
    default:
      break;
  }
};
